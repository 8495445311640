*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
.main-nav{
    display: flex;
    position: relative;
    overflow: hidden;
    flex-direction: row;
    column-gap: 20px;
    background-color: white;
    height:80px;
    box-shadow: 3px 3px 7px 0px #8da1ffab;
}
*{
    font-family: 'Poppins', sans-serif;
}
.nav-options{
    display: flex;
    flex-direction: row;
    column-gap: 20px;

}
.logo{
    margin-top: -70px;
    height:200px;
    width:200px
} 
.menu-icon{
   height:40px;
   margin-top: 20px;
   margin-left: 3px;
   display: none;
   cursor: pointer;
   
}
.menu-icon:hover{
    filter: invert(50%);
}
.btn h5:hover{
    color : #5271ff;
    transition: 0.4s all;
}
.btn{
    margin-top: 25px;
    font-size: 23px;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    letter-spacing: 1px;
}
.right-op{
    display: flex;
    position: absolute;
    right: 35px;
    margin-top:17px;
    column-gap: 10px;
    font-family: 'Tilt Neon', cursive;
    font-size: 23px;
    cursor: pointer;
    
   
}
.user-name{
    padding-top: 10px;
    cursor:pointer;
    font-size: 1.4rem;
}
.user-name:hover{
    color: blue;
}
.user-img:hover{
  filter:  sepia(1);
}
.user-img{
    cursor: pointer;
    width: 50px;
}
.loginOp{
    margin-top: 10px;
}
.loginOp :hover{
  
    color : blue;
    transition: 0.4s all; 
}
.user-op{
     margin-top: 8px;
    background-color: rgba(245, 245, 245, 0.825);
    cursor: pointer;
    padding: 10px 30px;
    border-radius: 10px;
    position: absolute;
    right : 30px;
    display: none;
    box-shadow: 2px 2px 3px 0px black;
    animation-name: leftOptionsOpening;
    animation-duration: 0.4s;
    z-index: 100;
}
.user-op ul{
    display: flex;
    flex-direction: column;
    row-gap: 10px;

    list-style: none;
}
.user-op li{
    font-family: 'Tilt Neon', cursive;
    font-size: 17px;
}
.left-options{
    display: none;
    margin-top: -1000px;
    position: absolute;
    animation-name: leftOptionsOpening;
    animation-duration: 0.4s;
    z-index: 100;


}
.l-options{
    cursor: pointer;
}
.footer{
    background-color: black;
    color: white;
    font-family: 'Tilt Neon', cursive;
    position: relative;
 
    bottom: 0px;
    width: 100%;
    padding: 10px;
    text-align: center;

}

/* animations */
@keyframes leftOptionsOpening{
    0%{
        margin-left: -150px;
        transform: scale(1.5);
    }
    to{
        margin-left: 10px;
        transform: scale(1);

    }
}

/* media queries Navbar */
@media only screen and (max-width: 800px) {
    .logo{
        margin-left: -20px;
    }
    .left-options{
        margin-top: 15px;
        margin-left: 10px;
        position: absolute;
        left: 0;
        height: 270px;
        width: 180px;
        text-align: center;
        /* border:1px solid black; */
        border-radius: 10px;
        background-color:white;
        box-shadow: 2px 2px 4px 0px rgba(225, 207, 207, 0.918);
       flex-direction: column;
      
    }
    #frm{
padding: 10px;
    }
   .nav-options{
    display: none;
   }
    .menu-icon{
        display: inline-block;
    }
  
  
  
}
@media only screen and (max-width: 400px) {
    
    .user-name{
        display: none;
    }
}


