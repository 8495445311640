.main-img img{
    height:400px;
    width: 500px;
    border-radius: 20px;
    box-shadow:7px 7px 8px 0px black;
}

.main-img{
    position: absolute;
    margin-top: 50px;
    right:105px;
     /* border: 2px solid red; */
    display: inline-block; 
}
@media (max-width:1210px){
       .main-img{ 
        display: none;
       }
}