.main-contact{
    display: flex;
    flex-direction: column;
    row-gap: 5px;
    justify-content: center;
    align-items: center;
    padding: 20px;
    border-radius: 20px;
    background-color: #5271ff;
    color: white;

}
.mnc{
    display: flex;
    flex-wrap: wrap;
    row-gap: 20px;
    column-gap: 20px;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
}

.getInTouch{
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    justify-content: center;
    align-items: center;
    padding: 20px;
    margin: 30px 50px;
    border-radius: 20px;

}
@media screen and (max-width:450px) {
    .getInTouch{
        margin: 0px;
    }
    .getInTouch input{
        width: 300px;
    }
    .getInTouch textarea{
        width: 300px;
    }
}