@import url(https://fonts.googleapis.com/css?family=PT+Mono);


.top{
    position: relative;
    margin-left: 100px;
    margin-top: 100px;
    overflow: hidden;
    display: inline-block;
    width: 500px ;
}

.top h1 {
    font-size: 4rem;
    font-weight: 300;
    font-family: 'PT Mono';
}

@media (max-width:768px) {
    .top h1 {
        font-size: 3rem;
    }
   .top{
    margin-left: 2px;
    width: 350px;
   }

}

.text-typing, .text-nowrap {
    white-space:nowrap;
    display: block;
}

a {
    color: #000;
	text-decoration: none;
  
}

a:hover,
a:focus {
    color: #222;
    background-size: 100% 100%;
    text-decoration: none;
}

.typing-words span {
    font-family: 'PT Mono';
    text-indent: 50px;
    position: absolute;
	overflow: hidden;
	white-space: nowrap;
	color: #555;
    visibility: hidden;
    border-right: 4px solid transparent;
}

/* steps is number of chars-1 */
.typing-words span:nth-child(1) {
	animation: typing 0.7s steps(5), typingReverse 0.7s steps(5) 0.7s, blink-wait 1s step-end 1 1.4s, blink-caret 0.6s step-end infinite;
}
.typing-words span:nth-child(2) { 
	animation: typing 0.7s steps(5) 2.4s, typingReverse 0.7s steps(5) 3.1s, blink-wait 1s step-end 1 3.8s, blink-caret 0.6s step-end infinite;
}
.typing-words span:nth-child(3) { 
	animation: typing 0.7s steps(5) 4.8s, typingReverse 0.7s steps(5) 5.5s, blink-wait 1s step-end 1 6.2s, blink-caret 0.6s step-end infinite;
}
.typing-words span:nth-child(4) { 
	animation: typing 0.7s steps(5) 7.2s, typingReverse 0.7s steps(5) 7.9s, blink-wait 1s step-end 1 8.5s, blink-caret 0.6s step-end infinite;
}
/* pause on last word, end cursor blink */
.typing-words span:nth-child(5) {  
	animation: blink-caret 0.6s step-end 7 9.5s, typing 1s steps(7) 9.5s forwards;
}

@keyframes typing {
    from { width: 0; visibility: hidden; 	color: orange;
	}
    to { width: 100%; visibility: visible; 	color: orange;
		; }
}

@keyframes typingReverse {
    from { width: 100%; visibility: visible; 	color: orange;
		; }
    to { width: 0; 
		color: orange;
}
}

@keyframes blink-wait {
    from, to { width: 0; visibility: hidden; }
    30%, 60% { width: 45px; visibility: visible; }
}

@keyframes blink-caret {
    from, to { border-color: transparent; }
    50% { border-color: red; }
}

