.card-body img{
    width: 300px;
    height: 220px;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    /* margin-top: -40px; */
    
}
#ph{
    width: 35px;
    height: 35px;
}

.card-body{
  
    display: flex;
    flex-direction: column;
    background-color: white;
    justify-content: space-between;
    align-items: center;
    border-radius: 10px;
    border-color: white;
    transition: all 0.3s ease-in-out;
    border:  1px solid whitesmoke;

}
.card-body:hover{
    box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
    cursor: pointer;
    transform: translateY(-5px);
}
.card-title{
    background-color: white;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
    font-size: 23px;
    padding: 5px;
   color:rgba(0, 0, 0, 0.784);
   padding: 10px;
}


.pricing-sec{
    display: inline-flex;
    flex-direction: column;
    row-gap: 4px;

}
.payment-sec{
    display: flex;
    flex-direction: row;
    column-gap: 90px;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;
    margin-left: 11px;
}
.book-now{
    border-radius: 45px;
    background-color: #8482FF;
    padding: 10px 20px;
    color: white;
    cursor: pointer;
}

.book-now:hover{
    background-color:whitesmoke;
    color:black;
    box-shadow:4px 4px 6px 0px rgba(120, 92, 205, 0.767);
}
