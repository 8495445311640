.loginForm{
    display: flex;
    flex-direction: column;
    row-gap: 4px;
    justify-content: center;
    align-items: center;
    margin: 18px 30px;
    padding: 20px;
    border-radius: 20px;
}
.loginForm img{
    height: 150px;
    width:150px;
}

.registeropt{
    font-size: 20px;
    font-weight: 600;
}
.registeropt:hover{
    color: rgba(107, 105, 105, 0.801);
    cursor: pointer;
}


.registerForm{
    display: flex;
    flex-direction: column;
    row-gap: 4px;
 
    justify-content: center;
    align-items: center;
    margin: 18px 30px;
    padding: 20px;
    border-radius: 20px;
}
.registerForm input{
    width:250px;
}
.registerForm img{
    height: 100px;
    width:100px;
    margin-left: 20px;
}
.err{
    color: red;
}
.gen-op{
    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    column-gap: 0px;
    width: 300px;
    color: #5271ff;
}


@media screen and (max-width:450px) {
    .loginForm{
        padding: 10px;
        margin: 98px 4px;
    }
    .loginForm input
    {
        width: 300px;
    }
    .registerForm{
        padding: 10px;
    }
  
}
@media screen and (max-width:380px){
    .registerForm{
        margin: 50px 30px;
    }
}




