.user-card img,.imgs img{
width: 180px;
height: 180px;
border-radius: 50%;
margin-top: 50px;
border-bottom: 2px blue solid;
border-top:2px yellow solid ;
border-left:2px red solid ;
border-left:2px green solid ;
background-color: white;

}
.imgs{
    display: inline-flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    row-gap: 20px;
    column-gap: 20px;
}
.imgs img{
    width: 200px;
height: 200px;
    border: none;
    cursor: pointer;
  
    
}
.imgs img:hover{
    transform: translateY(-6px);
    transition: 0.6s all;
}
.devs{
display: flex;
flex-direction: column;
row-gap: 20px;
justify-content: center;
align-items: center;
background-color: #5271ff;
margin: 30px 50px;
padding: 20px;
border-radius: 20px;
color: white;

}
.s{
    color: white;
    font-size: 70px ;
    font-family: 'Sacramento', cursive;
}
.title{
    color: rgb(242, 255, 0);
    font-weight: 700;
    letter-spacing: 1px;
}
.user-card {
    margin:30px 50px;
    display: inline-flex;
    flex-wrap: wrap;
    column-gap: 70px;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    padding: 20px;
    background-color: #5271ff;
    color: white;
}
.dev-card{
    margin:30px 50px;
    display: inline-flex;
    flex-direction: row;
    column-gap: 70px;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    padding: 20px;
    background-color: #5271ff;
    color: white;
}
.dt{
    display: inline-flex;
    flex-direction: column;
    row-gap: 10px;
    justify-content: center;
    align-items: center;
}
.user-card p{
    font-size: 18px;
    letter-spacing: 1px;
}
.socials img{
    width: 40px;
    height: 40px;
    margin-top: 0px;
}
.socials{
    display: inline-flex;
    column-gap: 10px;
}
.main-about{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    row-gap: 10px;
    margin-top: 50px;
}
.op{
    display: inline-flex;
    flex-direction: row;
    column-gap: 50px;
}
.aop{
    border-bottom: 2px solid white;
    font-weight: 600;
    cursor: pointer;
    font-size: 18px;
    letter-spacing: 1px;
    padding-bottom: 4px;
}
.aop:hover{
    border-bottom: 2px solid #5271ff;
    transition: 0.7s all;
    transform: translateY(-6px)

}
.act{
    border-bottom: 2px solid #5271ff;
}
.devimg{
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.devimg h4{
    color: black;
    background-color: white;
    padding: 4px 8px;
    border-radius: 5px;
}