.fac{
    display:flex;
    column-gap:50px;
  
    flex-wrap: wrap;
    row-gap: 50px;
    justify-content: center;
    align-items: center;
    font-family: 'Poppins', sans-serif;
    background-color: #5271ff;
    color: white;
    padding: 0px;
    box-shadow: 4px 4px 5px rgba(0, 0, 0, 0.499);
}
.headingg{
    font-size: 25px;
    padding-bottom: 20px;
    letter-spacing: 1px;
    margin-top: 50px;
}
.fac p{
    font-size: 17px;
    width: 250px;
    text-align: justify;
}
.main-heading{
    font-size: 30px;
    margin-left: 20px;
}
.exp{
    height: 500px;
}

