.main-car{
    /* margin-left: 50px; */
    margin-top: 15px;
    display: flex;
    flex-wrap: wrap;
    /* border: 2px solid red; */
    column-gap: 20px;
    row-gap: 10px;
    justify-content: center;
    align-items: center;
}
.choose-car{
     text-align: center;
     font-family: "Tilt-Neon",sans-serif;
     margin-top: 10px;

}
.h2-heading{
    text-align: center;
    font-family: "Tilt-Neon",sans-serif;
    margin-bottom: 10px;
}

.carcard-body{
      
      width: 200px;
      height: 180px;
      border-radius: 20px;
      background-color: whitesmoke;
     box-shadow: 3px 5px 4px 0px rgba(225, 207, 207, 0.918);

}
.carcard-body img{
    margin-left: 32px;
    margin-top: 10px;
    height: 120px;
    width:130px;
    border-radius: 50%;
}
.carcard-body:hover{
    box-shadow: 5px 5px 6px 0px rgba(120, 92, 205, 0.767);
}
.active{
 border: 4px  rgba(120, 92, 205, 0.767) solid;
}


.car-title{
     text-align: center;
     font-family: 'Tilt-Neon',sans-serif;
     margin-top: 10px;
}
#frm{
    display: flex;
    border-radius: 20px;
    padding: 40px 50px;
    margin: 0px 30px;
    flex-direction: column;
    row-gap: 5px;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    box-shadow: 2px 2px 7px #5271ff;  
}
.car-form{
    display: flex;
    border-radius: 20px;
    padding: 40px 50px;
    margin: 0px 30px;
    flex-wrap: wrap;
    row-gap: 30px;
    column-gap: 100px;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
}

input{
    background-color: #8da2ff11;
    border-radius: 8px;
     outline: none;
    width:300px;
    /* height:40px; */
    padding: 12px;
    border: 1px solid #5271ff;
    font-size: 15px;
    font-weight: 500;
    color:black;
    letter-spacing: 1px;
}
input::placeholder{
    color:rgba(50, 50, 50, 0.726);
}
input:focus{
    border: 2px solid #52ffa6;
}
textarea:focus{
    border: 2px solid #52ffa6;
}
textarea{
    letter-spacing: 1px;
    /* background-color: blue; */
    border: 2px solid rgba(49, 0, 0, 0.871);
    border-radius: 5px;
     outline: none;
    width:300px;
    border: 1px solid #5271ff;
    padding: 10px;
    font-size: 20px;
    font-family: 'Times New Roman', Times, serif;
    font-weight: 500;
    color:rgb(25, 33, 255);
}
textarea::placeholder{
    color:rgba(50, 50, 50, 0.726);
}
.book-btn{
    background-color:rgba(132, 119, 255, 0.823) ;
    color:rgb(255, 253, 253);
    padding: 10px 30px;
    font-size: large;
    border-radius: 10px;
    cursor: pointer;
    border: none;
}
.book-btn:hover{
    background-color:whitesmoke;
    color:black;
    box-shadow:4px 4px 6px 0px rgba(120, 92, 205, 0.767);
}
.mth{
    display: flex;
    flex-direction: row;
    column-gap: 5px;
    align-items: center;
    justify-content: center;
}
.mbtn{
    background-color: rgb(131, 131, 242);
    padding: 3px 10px;
    border-radius: 25px;
    color: white;
    cursor: pointer;
}
.mbtn:hover{
    background-color: aquamarine;
    color: black;
}
.acc{
    background-color: aquamarine;
    color: black;   
}
#pmt{
    display: none;
    flex-direction: column;
    row-gap: 5px;
    justify-content: center;
    align-items: center;
}
@media screen and (max-width:1096px) {
     .car-form{
        margin-left: 25px;

     }
     .h2-heading{
        margin-left: 160px;
        text-align: start;
     }
}